import React from 'react'
import { plansData } from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'
import './Plans.css'

const plans = () => {
    return (
        <div className="plans-container">
            <div className="blur plans-blur-1"></div>
            <div className="blur plans-blur-2"></div>
            <div className="programs-header" style={{ gap: '2rem' }}>
                <span className='stroke-text'>Ready to Start</span>
                <span>Your Journey</span>
                <span className='stroke-text'>Now With Us</span>
            </div>
            {/* Plans */}
            <div className="plans">
                {plansData.map((plan, i) => (
                    <div className="plan" key={i}>
                        {plan.icon}
                        <span>{plan.name}</span>
                        <span>$ {plan.price}</span>

                        <div className="features">
                            {plan.features.map((feature, i) => (
                                <div className="feature">
                                    <img src={whiteTick} alt="" />
                                    <span key={i}>{feature}</span>
                                </div>
                            ))}
                        </div>
                        <div className="">
                            <span>See More Benefits -{'>'} </span>
                        </div>
                        <div className="">
                            <button className="btn">Join Now <span> -{'>'} </span> </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default plans